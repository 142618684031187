import { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import ImageGrid from "./ImageGrid";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

export default function Gallery() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const { t } = useTranslation();

  const types = ["image/png", "image/jpeg"];

  const changeHandler = (e) => {
    e.preventDefault();
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError("");
    } else {
      setFile(null);
      setError("Please select an image file (png or jpeg)");
    }
  };

  return (
    <div className="gallery">
      <div className="col-lg-12 col-12 text-center mb-5 ">
        <h2 data-aos="fade-up" data-aos-delay="200">
          Gallery
        </h2>
      </div>
      <ImageGrid setSelectedImg={setSelectedImg} />
      {selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </div>
  );
}
